// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import "slick-carousel";
import "select2";

// custom

jQuery(document).ready(function () {

    jQuery("#search_type").select2();

    jQuery("#menu-phone-menu .menu-item-has-children").on("click", function () {
        const state = jQuery("> .sub-menu", this).hasClass("show");
        jQuery("#menu-phone-menu .menu-item-has-children .sub-menu").removeClass("show");
        jQuery("> .sub-menu", this).toggleClass("show", !state);
    });

    jQuery("#search_type").on("change", function () {
        if (jQuery("#search_type").val() === "website") {
            jQuery("#search_type").closest("form").attr("target", "_self");
        } else {
            jQuery("#search_type").closest("form").attr("target", "_blank");
        }

        const web_search_text = "Type in a title, author or keywords";
        const cat_search_text = "Type in a search phrase";
        jQuery("#search_type").val() === "website" ? jQuery("#search").attr("placeholder", cat_search_text) : jQuery("#search").attr("placeholder", web_search_text);
    });
});
