// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const accordions = document.querySelectorAll(".accordion");

const OPEN = (accordion_id) => {
    const accordion = document.getElementById(accordion_id);
    const accordion_content = accordion.querySelector(".accordion_content");

    if (accordion) {
        if (accordion.classList.contains("is-active")) {
            accordion.classList.remove("is-active");
            accordion.setAttribute("aria-expanded", "false");

            if (accordion_content) {
                accordion_content.setAttribute("aria-hidden", "true");
            }
        } else {
            accordion.classList.add("is-active");
            accordion.setAttribute("aria-expanded", "true");
            if (accordion_content) {
                accordion_content.setAttribute("aria-hidden", "false");
            }
        }
    }
};

for (let i = 0; i < accordions.length; i++) {
    const accordion_button = accordions[i].querySelector(".accordion_button");

    if (accordion_button) {
        accordion_button.addEventListener("click", (e) => {
            e.preventDefault();

            const accordion_id = accordion_button.dataset.accordion;

            if (accordion_id) {
                OPEN(accordion_id);
            }
        });
    }

    if (accordions[i].querySelector("mark")) {
        OPEN(accordions[i].id);
    }
}
